import handleFrontendError from './handleFrontendError';

export default function smoothScrollTo(hash: string, offset = 180) {
	if (typeof document !== 'undefined' && typeof hash === 'string' && hash.trim() !== '') {
		try {
			if (typeof document !== 'undefined') {
				const element = document.querySelector(hash);
				if (element) {
					const top = element.getBoundingClientRect().top + window.scrollY - offset;
					window.scrollTo({ top: top, behavior: 'smooth' });
				}
			}
		} catch (error) {
			handleFrontendError(error, false);
		}
	}
}
